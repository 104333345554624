


































































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

interface StepError {
  step: number;
  error: string;
}

export default defineComponent({
  components: {
    ORegistrationFeeInfo: () =>
      import(
        "@/components/organisms/event/modules/registrationModule/o-registration-fee-info.vue"
      ),
    ORegistrationFeeForm: () =>
      import(
        "@/components/organisms/event/modules/registrationModule/o-registration-fee-form.vue"
      ),
    ORegistrationFeeCosts: () =>
      import(
        "@/components/organisms/event/modules/registrationModule/o-registration-fee-costs.vue"
      ),
    ORegistrationFeeSummary: () =>
      import(
        "@/components/organisms/event/modules/registrationModule/o-registration-fee-summary.vue"
      ),
  },

  setup(_, { root }) {
    const model = reactive<{
      stepper: number;
      data: any;
      form: any;
      costs: any;
    }>({
      stepper: 1,
      data: {
        name: null,
        englishName: null,
        group: null,
        status: true,
        discountsIds: [],
        roomId: "",
        accommodationFrom: "",
        accommodationTo: "",
        accommodationResignation: false,
      },
      form: {
        counter: null,
        isCompanionRequired: false,
        isRegistrationCodeRequired: false,
        registrationFeeCodesData: {
          type: "single use",
          codes: [],
        },
        additionalField: false,
        group: null,
        additionalInformation: null,
        englishAdditionalInformation: null,
        additionalServicesIds: [],
      },
      costs: [
        {
          order: 0,
          price: 0,
          startDate: null,
          endDate: null,
          paymentDate: null,
          message: "Po tym terminie rejestracja zostanie anulowana.",
        },
      ],
    });

    const dataSummary = computed(() => ({
      data: model.data,
      form: model.form,
      costs: model.costs,
    }));

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      errors: computed((): StepError[] => {
        const errors: StepError[] = [];

        if (!model.data.name)
          errors.push({
            step: 1,
            error: root.$tc("layout.errors.registrationFeeNameRequired"),
          });
        if (!model.data.group)
          errors.push({
            step: 1,
            error: root.$tc("layout.errors.registrationFeeGroupRequired"),
          });
        if (
          model.form.isRegistrationCodeRequired &&
          !model.form.registrationFeeCodesData.codes.length
        )
          errors.push({
            step: 2,
            error: root.$tc("layout.errors.registrationFeeCodeRequired"),
          });

        return errors;
      }),
      feeGroups: [],
      participantGroups: [],
      discounts: [],
      services: [],
      hotels: [],
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isStepEditable = (step: number) => {
      // return model.stepper > step
      return true;
    };

    const isStepComplete = (step: number) => {
      return (
        model.stepper > step &&
        !state.errors.find((error) => error.step == step)
      );
    };

    const isStepValid = (step: number) => {
      return !state.errors.find((error) => error.step == step);
    };

    const isFormValid = computed(() => !state.errors.length);

    const goToErrors = () => {
      let lowest = Number.POSITIVE_INFINITY;
      let highest = Number.NEGATIVE_INFINITY;

      let tmp: number;

      for (let i = state.errors.length - 1; i >= 0; i--) {
        tmp = state.errors[i].step;
        if (tmp < lowest) lowest = tmp;
        if (tmp > highest) highest = tmp;
      }

      model.stepper = lowest;
    };

    const fetchFeeGroups = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`/event/${root.$route.params.id}/registration-fee/group`)
        .then(({ data }) => {
          state.feeGroups = data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.feeGroups = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchFeeGroups);

    const fetchParticipantGroups = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`/event/${root.$route.params.id}/participant/group/select`)
        .then(({ data: { groups } }) => {
          state.participantGroups = groups;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.participantGroups = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchParticipantGroups);

    const fetchDiscounts = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/discount`)
        .then(({ data: { discounts } }) => {
          state.discounts = discounts
            .filter((el: any) => !el.isGlobal)
            .map((d: any) => ({
              id: d.id,
              name: d.name,
            }));
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.discounts = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchDiscounts);

    const fetchServices = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/additional-service-group`)
        .then(({ data: { additionalServiceGroups } }) => {
          state.services = additionalServiceGroups
            .map((item: any) =>
              item.additionalServices.map((el: any) => ({
                groupName: item.name,
                groupIsEnabled: item.isEnabled,
                id: el.id,
                name: el.name,
                isEnabled: el.isEnabled,
              }))
            )
            .flat();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.services = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchServices);

    const fetchHotels = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/hotel`)
        .then(({ data: { hotels } }) => {
          state.hotels = hotels;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.hotels = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchHotels);

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        order: model.data.order,
        name: model.data.name || undefined,
        englishName: model.data.englishName || undefined,
        registrationFeeGroup: model.data.group || undefined,
        state: model.data.status || undefined,
        discountsIds:
          model.data.discountsIds.map((d: any) => ({ id: d })) || undefined,
        participantGroup: model.data.participantGroup || undefined,
        roomId: model.data.roomId || undefined,
        accommodationFrom: model.data.accommodationFrom || undefined,
        accommodationTo: model.data.accommodationTo || undefined,
        accommodationResignation: model.data.accommodationResignation || false,
        amount: model.form.counter || null,
        isCompanionRequired: model.form.isCompanionRequired || false,
        isRegistrationCodeRequired:
          model.form.isRegistrationCodeRequired || false,
        registrationFeeCodesData: model.form.isRegistrationCodeRequired
          ? model.form.registrationFeeCodesData
          : null,
        additionalField: model.form.additionalField || false,
        additionalInformation: model.form.additionalInformation || undefined,
        englishAdditionalInformation:
          model.form.englishAdditionalInformation || undefined,
        additionalServicesIds: model.form.additionalServicesIds || [],
        registrationFeeTerms: model.costs || undefined,
      };

      state.loading = true;

      axiosInstance
        .post(`event/${root.$route.params.id}/registration-fee`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          model.stepper = 1;
          (model.data = {
            name: null,
            group: null,
            status: true,
            discountsIds: [],
            participantGroup: "",
            roomId: "",
            accommodationFrom: "",
            accommodationTo: "",
            accommodationResignation: false,
          }),
            (model.form = {
              counter: null,
              isCompanionRequired: false,
              isRegistrationCodeRequired: false,
              registrationFeeCodesData: {
                type: "",
                codes: [],
              },
              additionalField: false,
              group: null,
              additionalInformation: null,
              additionalServicesIds: [],
            }),
            (model.costs = [
              {
                price: 0,
                startDate: null,
                endDate: null,
                paymentDate: null,
                message: "Po tym terminie rejestracja zostanie anulowana.",
              },
            ]);
        })
        .catch((error) => {
          state.error = error.response.status;
        })
        .finally(() => (state.loading = false));
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return "Brak uprawnień";
        case 404:
          return "Endpointu nie znaleziono";
        case 409:
          return "Opłata już istnieje";
        case 410:
          return "Nie można stworzyć płatnej rejestracji bez podania terminu płatności";
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return "Wystąpił bład";
      }
    };

    return {
      state,
      model,
      isStepEditable,
      isStepComplete,
      isStepValid,
      isFormValid,
      goToErrors,
      dataSummary,
      onSubmit,
      getErrorMessage,
    };
  },
});
